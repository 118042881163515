import { getCookie } from "./utils";
const isValidAdobeMboxCookie = (mboxCookie)=>{
    const mboxRegex = // eslint-disable-next-line unicorn/better-regex
    /[a-zA-Z]+#[a-zA-Z0-9._]+#\d+\|[a-zA-Z]+#[a-zA-Z0-9._]+#\d+/g;
    return mboxCookie && mboxRegex.test(mboxCookie);
};
const isValidAdobeAmcvCookie = ()=>{
    var ref;
    const amcvCookieKey = (ref = document.cookie// eslint-disable-next-line unicorn/better-regex
    .match(/AMCV_[a-zA-Z0-9%]+AdobeOrg/g)) === null || ref === void 0 ? void 0 : ref.pop();
    if (!amcvCookieKey) {
        return false;
    }
    const amcvCookieValue = getCookie({
        name: amcvCookieKey
    });
    if (!amcvCookieValue) {
        return false;
    }
    const uriDecodedCookieValue = decodeURIComponent(amcvCookieValue);
    const amcvCookieValuesSplit = uriDecodedCookieValue.split("|");
    const visitorIdKeyIndex = amcvCookieValuesSplit.indexOf("MCMID");
    if (visitorIdKeyIndex < 0) {
        return false;
    }
    return true;
};
export const waitForAdobeTargetCookie = async ()=>{
    let timeoutId;
    try {
        const promise = new Promise((resolve)=>{
            const interval = setInterval(()=>{
                const mboxCookie = getCookie({
                    name: "mbox"
                });
                if (isValidAdobeAmcvCookie() && isValidAdobeMboxCookie(mboxCookie)) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
        const timeout = new Promise((_, reject)=>{
            timeoutId = setTimeout(()=>reject(), 3000);
        });
        await Promise.race([
            promise,
            timeout
        ]);
    } catch  {
        console.error("Adobe took too long to return the 'mbox' and 'marketingCloudVisitorID' cookies");
    } finally{
        clearTimeout(timeoutId);
    }
};
