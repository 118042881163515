import { getToken, isSSR } from "@cinch-labs/shared-util";
import { Env, readFromEnv } from "@cinch-nx/environments";
import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
import { User, UserManager, WebStorageStateStore } from "oidc-client-ts";
import { create } from "zustand";
import { mapPreferenceUpdateToUpdatedPreferences } from "./util/preferences";
let userStoreConfig = {
    authKey: "",
    legacyAuthKey: "",
    profileServiceUrl: "",
    identityServiceUrl: "",
    auth0ServiceUrl: "",
    auth0DatabaseName: "",
    auth0ClientId: "",
    redirectUrlKey: "",
    cinchUrl: ""
};
let httpClient;
let userManagerInstance;
let onSessionExpiredCallback;
export const initUserStore = (config, axiosImpl = axios)=>{
    const useRefreshToken = readFromEnv(Env.UseRefreshToken) === "true";
    userStoreConfig = {
        identityServiceUrl: readFromEnv(Env.IdentityServiceUrl),
        auth0ServiceUrl: readFromEnv(Env.Auth0ServiceUrl),
        auth0DatabaseName: readFromEnv(Env.Auth0DatabaseName),
        auth0ClientId: readFromEnv(Env.Auth0ClientId),
        profileServiceUrl: readFromEnv(Env.ProfileServiceUrl),
        redirectUrlKey: readFromEnv(Env.RedirectUrlKey),
        authKey: readFromEnv(Env.AuthKey),
        legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
        cinchUrl: readFromEnv(Env.cinchUrl),
        ...config
    };
    const _httpClient = axiosImpl.create();
    let accessToken;
    _httpClient.interceptors.request.use(async (request)=>{
        if (useRefreshToken) {
            console.log("Initiating user manager...");
            if (userManagerInstance) {
                datadogRum.addAction("Fetching existing user");
                const user = await userManagerInstance.getUser();
                request.headers = {
                    ...request.headers,
                    Authorization: `Bearer ${user === null || user === void 0 ? void 0 : user.access_token}`
                };
                return request;
            }
            datadogRum.addAction("Initiating UserManager");
            userManagerInstance = new UserManager({
                authority: userStoreConfig.auth0ServiceUrl,
                client_id: userStoreConfig.auth0ClientId,
                redirect_uri: `${window.location.origin}/auth-callback/`,
                silent_redirect_uri: `${window.location.origin}/auth-callback/`,
                post_logout_redirect_uri: `${window.location.origin}/`,
                response_type: "code",
                scope: "openid profile email offline_access",
                automaticSilentRenew: true,
                loadUserInfo: false,
                userStore: new WebStorageStateStore({
                    prefix: "oxford-",
                    store: window.localStorage
                })
            });
            const storedUser = localStorage.getItem(userStoreConfig.authKey);
            let userFromStorage = null;
            if (storedUser) {
                userFromStorage = new User(JSON.parse(storedUser));
                userManagerInstance.storeUser(userFromStorage);
            }
            const user1 = await userManagerInstance.getUser();
            userManagerInstance.events.addSilentRenewError(async ()=>{
                datadogRum.addError(new Error("AddSilentRenewError"));
                userManagerInstance.clearStaleState();
                userManagerInstance.removeUser();
                window.location.assign("/login");
            });
            accessToken = user1 === null || user1 === void 0 ? void 0 : user1.access_token;
        } else {
            const token = getToken({
                authKey: userStoreConfig.authKey,
                legacyAuthKey: userStoreConfig.legacyAuthKey
            });
            accessToken = token === null || token === void 0 ? void 0 : token.access_token;
        }
        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${accessToken}`
        };
        return request;
    });
    _httpClient.interceptors.response.use(undefined, (error)=>{
        var ref, ref1;
        if ((error === null || error === void 0 ? void 0 : (ref = error.response) === null || ref === void 0 ? void 0 : ref.status) === 401 || (error === null || error === void 0 ? void 0 : (ref1 = error.response) === null || ref1 === void 0 ? void 0 : ref1.status) === 403) {
            datadogRum.addError(new Error("SessionExpiredError"));
            onSessionExpiredCallback === null || onSessionExpiredCallback === void 0 ? void 0 : onSessionExpiredCallback();
        }
        return Promise.reject(error);
    });
    httpClient = _httpClient;
};
export const useUserStore = create((set, get)=>{
    onSessionExpiredCallback = ()=>{
        set({
            status: "expired"
        });
    };
    return {
        status: "unknown",
        setStatus: (status)=>set({
                status
            }),
        fetchUser: async ()=>{
            const { authKey , legacyAuthKey , profileServiceUrl  } = userStoreConfig;
            if (isSSR()) {
                return;
            }
            const token = getToken({
                authKey,
                legacyAuthKey
            });
            if (!token) {
                return set(()=>({
                        status: "invalid"
                    }));
            }
            set(()=>({
                    status: "pending",
                    token
                }));
            try {
                const profile = (await httpClient.get(`${profileServiceUrl}/v2/userprofile`)).data;
                const { preferenceCall , preferenceEmail , preferenceSms , ...profileWithoutPreferences } = profile;
                return set(()=>({
                        status: "valid",
                        profile: profileWithoutPreferences,
                        preferences: {
                            preferenceCall,
                            preferenceEmail,
                            preferenceSms
                        },
                        token
                    }));
            } catch  {
                datadogRum.addError(new Error("FetchUserProfileActionFailed"));
            }
        },
        updatePassword: async (oldPassword, newPassword)=>{
            try {
                const { identityServiceUrl  } = userStoreConfig;
                set(()=>({
                        status: "updating"
                    }));
                await httpClient.put(`${identityServiceUrl}/password`, {
                    oldPassword,
                    newPassword
                });
                set(()=>({
                        status: "valid"
                    }));
            } catch  {
                datadogRum.addError(new Error("UpdatePasswordActionFailed"));
            }
        },
        resetPassword: async (email)=>{
            try {
                const postUrl = "/dbconnections/change_password";
                const { auth0ServiceUrl , auth0DatabaseName , auth0ClientId  } = userStoreConfig;
                const data = {
                    email: email,
                    connection: auth0DatabaseName,
                    client_id: auth0ClientId
                };
                const axiosInstance = axios.create({
                    baseURL: auth0ServiceUrl,
                    validateStatus: ()=>true
                });
                const result = await axiosInstance.post(postUrl, data);
                return {
                    statusCode: result.status
                };
            } catch (error) {
                datadogRum.addError(new Error("ResetPasswordActionFailed"));
                throw error;
            }
        },
        updateProfile: async (updatedFields)=>{
            try {
                const { profileServiceUrl  } = userStoreConfig;
                set(()=>({
                        status: "updating"
                    }));
                const fullProfile = {
                    ...get().profile,
                    ...updatedFields
                };
                const updatedProfile = {
                    ...updatedFields
                };
                await httpClient.put(`${profileServiceUrl}/v2/userprofile`, updatedProfile);
                set(()=>({
                        profile: fullProfile,
                        status: "valid"
                    }));
            } catch  {
                datadogRum.addError(new Error("UpdateProfileActionFailed"));
            }
        },
        updateProfilePreferences: async (preferenceUpdate)=>{
            try {
                const { profileServiceUrl  } = userStoreConfig;
                set(()=>({
                        status: "updating"
                    }));
                await httpClient.put(`${profileServiceUrl}/v2/userprofile`, {
                    ...preferenceUpdate,
                    context: "Updated from Marketing Preferences page in profile"
                });
                const existingPreferences = get().preferences;
                set(()=>({
                        preferences: {
                            ...existingPreferences,
                            ...mapPreferenceUpdateToUpdatedPreferences(preferenceUpdate)
                        },
                        status: "valid"
                    }));
            } catch  {
                datadogRum.addError(new Error("UpdateProfilePreferencesActionFailed"));
            }
        },
        logout: ()=>{
            const { redirectUrlKey  } = userStoreConfig;
            localStorage.removeItem(redirectUrlKey);
            window.location.assign("/logout");
        },
        login: ()=>{
            datadogRum.addAction("login redirect");
            window.location.assign("/login");
        },
        register: ()=>{
            window.location.assign("/login");
        }
    };
});
