import { initAdobeTargetClient } from "./adobe-target-client";
import { cookiesAreInvalid, getAdobeVisitorID } from "./adobe-target-server-side.helper.pd-impl";
const DEFAULT_EXPERIENCE_UNAVAILABLE = [];
export const getAdobeExperimentSSR = async (experimentNames, context)=>{
    try {
        const cookies = context.req.cookies;
        if (cookiesAreInvalid(cookies)) {
            return DEFAULT_EXPERIENCE_UNAVAILABLE;
        }
        const mboxCookie = cookies["mbox"];
        const marketingCloudVisitorID = getAdobeVisitorID(cookies);
        if (!mboxCookie || !marketingCloudVisitorID) {
            return DEFAULT_EXPERIENCE_UNAVAILABLE;
        }
        return await getExperiments(experimentNames, mboxCookie, marketingCloudVisitorID);
    } catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        console.error(`Failed to retrieve Adobe Experiment data: ${errorMessage}`);
        return DEFAULT_EXPERIENCE_UNAVAILABLE;
    }
};
const getExperiments = async (experimentNames, abTestCookie, marketingCloudVisitorID)=>{
    const experiments = [];
    const adobeTargetClient = await initAdobeTargetClient();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await adobeTargetClient// eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .getAttributes(experimentNames, {
        targetCookie: abTestCookie,
        visitorCookie: marketingCloudVisitorID
    }).then((response)=>{
        for (const experimentName of experimentNames){
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const experiment = response.asObject(experimentName);
            if (experiment && experiment.experienceId !== undefined && experiment.experienceId !== null) {
                experiments.push({
                    name: experimentName,
                    experienceId: experiment.experienceId
                });
            }
        }
    });
    return experiments;
};
