export const BaseEnvironment = {
    RedirectUrlKey: "REDIRECT_URL_KEY"
};
export const EnvironmentFromFile = {
    Adobe: "ADOBE_DTM",
    LegacyAuthKey: "IDS_AUTH_KEY",
    AuthKey: "AUTH0_AUTH_KEY",
    ProfileServiceUrl: "PROFILE_SERVICE_URL",
    StaticAssetsUrl: "STATIC_ASSETS_URL",
    OrderServiceUrl: "ORDER_SERVICE_URL",
    CinchcareServiceUrl: "CINCHCARE_SERVICE_URL",
    DeliveryServiceURl: "DELIVERY_SERVICE_URL",
    SearchServiceUrl: "SEARCH_SERVICE_URL",
    PermissionsUrl: "PERMISSION_URL",
    SearchValuePropositions: "SEARCH_VALUE_PROPOSITIONS",
    SegmentKey: "SEGMENT_KEY",
    IdentityServiceUrl: "IDENTITY_SERVICE_URL",
    Auth0ServiceUrl: "AUTH0_SERVICE_URL",
    Auth0DatabaseName: "AUTH0_DATABASE_NAME",
    Auth0ClientId: "AUTH0_CLIENT_ID",
    promoBannerName: "PROMO_BANNER_NAME",
    cinchUrl: "CINCH_URL",
    cinchAppUrl: "CINCH_APP_URL",
    TrustpilotUrl: "TRUSTPILOT_URL",
    StoryblokBFFComponent: "STORYBLOK_BFF_COMPONENT",
    consumerValuationsServiceUrl: "CONSUMER_VALUATIONS_SERVICE_URL",
    CinchChargeCardRequestUrl: "CINCH_CHARGE_CARD_REQUEST_URL",
    CinchChargeCardRequestAPIKey: "CINCH_CHARGE_CARD_REQUEST_API_KEY",
    ProductDetailServiceUrl: "PRODUCT_DETAIL_SERVICE_URL",
    ReservationStatusUrl: "RESERVATION_STATUS_URL",
    InventoryServiceHistoryUrl: "INVENTORY_SERVICE_HISTORY_URL",
    IOSAppStoreUrl: "IOS_APP_STORE_URL",
    AndroidAppStoreUrl: "ANDROID_APP_STORE_URL",
    AdminFeeReturnToCheckout: "ADMIN_FEE_RETURN_TO_CHECKOUT",
    AdminFeeValue: "ADMIN_FEE_VALUE",
    AdminFeeLoyalty: "ADMIN_FEE_LOYALTY",
    CheckoutReservationsDecoupling: "CHECKOUT_RESERVATIONS_DECOUPLING",
    CheckoutReservationsDecouplingPhaseTwo: "CHECKOUT_RESERVATIONS_DECOUPLING_PHASE_TWO",
    ReservationServiceUrl: "RESERVATION_SERVICE_URL",
    DeliverySlotAvailabilityServiceUrl: "DELIVERY_SLOT_AVAILABILITY_SERVICE_URL",
    LogisticsFeesServiceUrl: "LOGISTICS_FEES_SERVICE_URL",
    FinanceApplicationServiceUrl: "FINANCE_APPLICATION_SERVICE_URL",
    FinanceQuoteServiceUrl: "FINANCE_QUOTE_SERVICE_URL",
    FinanceSoftSearchUrlPath: "FINANCE_SOFT_SEARCH_URL_PATH",
    PaymentLinkServiceUrl: "PAYMENT_LINK_SERVICE_URL",
    StripePublishableKey: "STRIPE_PUBLISHABLE_KEY",
    GapServiceUrl: "GAP_SERVICE_URL",
    ServicingAndWarrantyFlag: "SERVICING_AND_WARRANTY_FLAG",
    DisableCinchCare: "DISABLE_CINCHCARE",
    GapInsuranceFlag: "GAP_INSURANCE_FLAG",
    CustomerDataAPIKey: "CUSTOMER_DATA_API_KEY",
    CustomerDataAPIUrl: "CUSTOMER_DATA_API_URL",
    EnableCinchAppUserAgentInUsedCarsSSR: "ENABLE_CINCH_APP_USER_AGENT_IN_USED_CARS_SSR",
    SearchMultipleImages: "SEARCH_MULTIPLE_IMAGES",
    EnabledZendeskScript: "ENABLE_ZENDESK_SCRIPT",
    VehicleCardV2: "VEHICLE_CARD_V2",
    NextBestAction: "NEXT_BEST_ACTION",
    SearchStoreLocations: "SEARCH_STORE_LOCATIONS",
    VehicleOwnershipServiceUrl: "VEHICLE_OWNERSHIP_SERVICE_URL",
    AdminFeeCompliance: "ENABLE_ADMIN_FEE_COMPLIANCE",
    enableBuyNowMessaging: "ENABLE_BUY_NOW_CTA_MESSAGING",
    EnableEmailQuote: "ENABLE_EMAIL_QUOTE",
    EnableFavouritingHomePageChanges: "HOMEPAGE_FAVOURITING",
    EnableProductDetailPageVariant: "ENABLE_PRODUCT_DETAIL_PAGE_VARIANT",
    EnableQuotesMarketing: "ENABLE_QUOTES_MARKETING",
    EnablePXOnHomepageHero: "ENABLE_PX_ON_HOMEPAGE_HERO",
    SearchFinanceTypeFilter: "SEARCH_FINANCE_TYPE_FILTER",
    ShowQuoteTermMonths: "SHOW_QUOTE_TERM_MONTHS",
    ShowScorelessTrustpilot: "SHOW_SCORELESS_TRUSTPILOT",
    SoftSearchChanges: "SOFT_SEARCH_CHANGES",
    showCinchCoverInProfile: "SHOW_CINCH_COVER_IN_PROFILE",
    showMultiTermVAPsInProfile: "SHOW_MULTI_TERM_VAPS_IN_PROFILE",
    bankServiceUrl: "BANK_SERVICE_URL",
    DeliveryFeeStandard: "DELIVERY_FEE_PENCE_STANDARD",
    DeliveryFeeExpress: "DELIVERY_FEE_PENCE_EXPRESS",
    VapServiceUrl: "VAP_SERVICE_URL",
    UseRefreshToken: "USE_REFRESH_TOKEN",
    SearchRecentlyViewedVehicles: "SEARCH_RECENTLY_VIEWED_VEHICLES"
};
export const EnvironmentFromProcess = {
    DataDogAPIKey: "DATADOG_API_KEY",
    DataDogClientId: "DATADOG_CLIENT_ID",
    DataDogRumApplicationId: "DATADOG_RUM_APPLICATION_ID",
    DataDogRumClientToken: "DATADOG_RUM_CLIENT_TOKEN",
    DataDogRumGlobalSampleRate: "DATADOG_RUM_GLOBAL_SAMPLE_RATE",
    DataDogRumGlobalReplaySampleRate: "DATADOG_RUM_GLOBAL_REPLAY_SAMPLE_RATE",
    DataDogServiceName: "DATADOG_SERVICE_NAME",
    BuildId: "BUILD_ID"
};
export const Env = {
    ...BaseEnvironment,
    ...EnvironmentFromFile,
    ...EnvironmentFromProcess
};
