// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import TargetClient from "@adobe/target-nodejs-sdk";
const PROD_TRACKING_SERVER = "bcacinchprod.112.2o7.net";
const DEV_TRACKING_SERVER = "bcaoxforddev.112.2o7.net";
const isProd = process.env["STAGE_NAME"] === "production";
const trackingServerDomain = isProd ? PROD_TRACKING_SERVER : DEV_TRACKING_SERVER;
export const initAdobeTargetClient = async ()=>{
    try {
        let adobeTargetClient;
        const initTargetClient = new Promise((resolve)=>{
            const clientReadyCallback = ()=>resolve();
            const clientConfig = createAdobeTargetClientConfig(clientReadyCallback);
            adobeTargetClient = TargetClient.create(clientConfig);
        });
        await Promise.all([
            initTargetClient
        ]);
        return adobeTargetClient;
    } catch  {
        throw new Error("Failed to initialise Adobe Target Client");
    }
};
const createAdobeTargetClientConfig = (clientReadyCallback)=>({
        client: "bca",
        organizationId: "E5F2C8A15481C0E20A4C98BC@AdobeOrg",
        decisioningMethod: "on-device",
        serverDomain: trackingServerDomain,
        events: {
            clientReady: clientReadyCallback
        }
    });
