/* eslint-disable unicorn/no-useless-undefined */ import { dashify, formatNumber } from "@cinch-labs/shared-util";
import { Env, readFromEnv } from "@cinch-nx/environments";
import { decorateOptions } from "../utils/decorate-options";
import { Datadog } from "@cinch-nx/landing-ui-shared";
const { Actions  } = Datadog;
const formatPrice = (price)=>price ? `£${price}` : "any";
export const TOTAL_PRICE = "total-price";
export const MONTHLY_PRICE = "monthly-price";
export function getSearchButtonText(quantity) {
    return quantity === 1 ? "Search 1 car" : `Search ${formatNumber(quantity)} cars`;
}
export function onMakeSelected({ setSelectedMake , setSelectedModel , setMakeOptions , section , handleOnChangeTracking , event  }) {
    const isPlaceholderSelected = event.target.value === "Select make";
    const value = event.target.value === "Any" || isPlaceholderSelected ? undefined : event.target.value;
    handleOnChangeTracking({
        trackEventLabel: "make",
        trackEventAction: "select",
        value,
        datadogActionName: Actions.SELECT_MAKE_OPTION,
        trackEventSection: section
    });
    setSelectedMake(value || "");
    setMakeOptions((makes)=>removeStockCountFromSelectedMake(makes, event.target.value));
    setSelectedModel(undefined);
}
export function onModelSelected({ setSelectedModel , handleOnChangeTracking , event , section  }) {
    const value = event.target.value === "Any" ? undefined : event.target.value;
    handleOnChangeTracking({
        trackEventLabel: "model",
        trackEventAction: "select",
        value,
        datadogActionName: Actions.SELECT_MODEL_OPTION,
        trackEventSection: section
    });
    setSelectedModel(value || "");
}
export function onSubmit({ handleOnSubmitTracking , maxTotalPrice , radioChecked , selectedMake , selectedModel , event , section  }) {
    const price = radioChecked === MONTHLY_PRICE ? `monthly price: ${formatPrice(maxTotalPrice)}` : `total price: ${formatPrice(maxTotalPrice)}`;
    const shouldShowFinanceTypeFilter = readFromEnv(Env.SearchFinanceTypeFilter) === "true";
    handleOnSubmitTracking(selectedMake, selectedModel, price, section);
    event.preventDefault();
    const urlParams = new URLSearchParams();
    if (maxTotalPrice && maxTotalPrice !== -1) {
        urlParams.append("toPrice", maxTotalPrice.toString());
    }
    if (radioChecked === MONTHLY_PRICE) {
        shouldShowFinanceTypeFilter ? urlParams.append("financeType", "any") : urlParams.append("useMonthly", "true");
    }
    let url = `/used-cars`;
    if (selectedMake && selectedMake !== "any") {
        url += `/${dashify(selectedMake)}`;
    }
    if (selectedModel && selectedModel !== "any" && selectedMake && selectedMake !== "any") {
        url += `/${dashify(selectedModel)}`;
    }
    window.location.assign(`${url}${urlParams.toString().length > 0 ? `?${urlParams.toString()}` : ""}`);
}
export async function updateSearchButtonLabel({ selectedMake , selectedModel , radioChecked , maxTotalPrice , setButtonText  }) {
    const urlParams = new URLSearchParams({
        pageSize: "0"
    });
    const shouldShowFinanceTypeFilter = readFromEnv(Env.SearchFinanceTypeFilter) === "true";
    if (selectedMake && selectedMake !== "any") {
        urlParams.append("make", selectedMake);
    }
    if (selectedModel && selectedModel !== "any" && selectedMake && selectedMake !== "any") {
        urlParams.append("model", selectedModel);
    }
    if (radioChecked === MONTHLY_PRICE) {
        shouldShowFinanceTypeFilter ? urlParams.append("financeType", "any") : urlParams.append("useMonthly", "true");
    }
    if (maxTotalPrice !== undefined) {
        urlParams.append("toPrice", maxTotalPrice.toString());
    }
    const response = await getSearchResults(urlParams);
    const buttonText = getSearchButtonText(response.searchResultsCount);
    setButtonText(buttonText);
}
export async function updateStockLevels({ selectedMake , selectedModel , radioChecked , maxTotalPrice , totalStock , setMakeOptions , setModelOptions , setPriceBucket , setMonthlyPriceBucket  }) {
    const urlParams = new URLSearchParams();
    const shouldShowFinanceTypeFilter = readFromEnv(Env.SearchFinanceTypeFilter) === "true";
    if (selectedMake && selectedMake !== "any") {
        urlParams.append("make", selectedMake);
    }
    if (selectedModel && selectedModel !== "any" && selectedMake && selectedMake !== "any") {
        urlParams.append("model", selectedModel);
    }
    if (radioChecked === MONTHLY_PRICE) {
        shouldShowFinanceTypeFilter ? urlParams.append("financeType", "any") : urlParams.append("useMonthly", "true");
    }
    if (maxTotalPrice !== undefined) {
        urlParams.append("toPrice", maxTotalPrice.toString());
    }
    const response = await getSearchResults(urlParams);
    const { makes  } = response.facets;
    if (maxTotalPrice !== undefined) {
        const urlParams1 = new URLSearchParams({
            perPage: "0"
        });
        if (radioChecked === MONTHLY_PRICE) {
            shouldShowFinanceTypeFilter ? urlParams1.append("financeType", "any") : urlParams1.append("useMonthly", "true");
        }
        urlParams1.append("toPrice", maxTotalPrice.toString());
        const data = await getSearchResults(urlParams1);
        totalStock = data.searchResultsCount;
    }
    const makeOptions = decorateOptions(makes.map((data)=>toMakes(data)).map((make)=>{
        let label = `${make.name}`;
        if (make.name !== selectedMake) {
            label += ` (${formatNumber(make.stock)})`;
        }
        return {
            label: label,
            value: make.name,
            count: `${make.stock}`
        };
    }), totalStock || 0);
    setMakeOptions(makeOptions);
    if (selectedMake && selectedMake !== "any") {
        const make = makes.map((data)=>toMakes(data)).find((make)=>make.name === selectedMake);
        const modelTotalStock = response.searchResultsCount;
        const modelOptions = modelToOptions((make === null || make === void 0 ? void 0 : make.models) ?? [], selectedModel);
        const decoratedModelOptions = decorateOptions(modelOptions, modelTotalStock || 0);
        setModelOptions(decoratedModelOptions);
    }
    setPriceBucket(response.facets.priceBucket);
    setMonthlyPriceBucket(response.facets.monthlyPriceBucket);
}
export function updateModelOptions({ selectedMake , selectedModel , makes , setModelOptions  }) {
    if (selectedMake && !/any/gi.test(selectedMake)) {
        const make = makes.find((make)=>make.name === selectedMake);
        setModelOptions(modelToOptions(make.models, selectedModel));
        return;
    }
    setModelOptions([]);
}
function modelToOptions(models, selectedModel) {
    return models.map((model)=>{
        let label = `${model.name}`;
        if (model.name !== selectedModel) {
            label += ` (${formatNumber(model.stock)})`;
        }
        return {
            label,
            value: model.name,
            count: `${model.stock}`
        };
    });
}
export async function getSearchResults(urlParams) {
    const VEHICLE_SEARCH_URL = `${readFromEnv(Env.SearchServiceUrl)}/vehicles`;
    const url = `${VEHICLE_SEARCH_URL}?${urlParams.toString()}`;
    const request = await fetch(url);
    const response = await request.json();
    return response;
}
export function toMakes(make) {
    return {
        name: make.name,
        stock: make.doc_count,
        models: make.models.map((model)=>({
                name: model.name,
                stock: model.doc_count
            }))
    };
}
function removeStockCountFromSelectedMake(makes, selectedMake) {
    if (selectedMake && selectedMake !== "any") {
        return makes.map((make)=>{
            if (make.value === selectedMake) {
                make.label = selectedMake;
            }
            return make;
        });
    }
    return makes;
}
