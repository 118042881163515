const tokenIsInDate = (token)=>token.expires_at > Date.now() / 1000;
const isToken = (maybeToken)=>Object.prototype.hasOwnProperty.call(maybeToken, "expires_at");
export const getToken = ({ authKey , legacyAuthKey  })=>{
    if ("object" === "undefined") return;
    const maybeLegacyAuthToken = JSON.parse(localStorage.getItem(authKey) ?? "{}");
    const maybeAuthToken = JSON.parse(localStorage.getItem(legacyAuthKey) ?? "{}");
    if (isToken(maybeAuthToken) && tokenIsInDate(maybeAuthToken)) {
        return maybeAuthToken;
    }
    if (isToken(maybeLegacyAuthToken) && tokenIsInDate(maybeLegacyAuthToken)) {
        return maybeLegacyAuthToken;
    }
    return null;
};
